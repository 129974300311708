import AuthContextProvider from "../contexts/AuthContextProvider";
import RoleContextProvider from "../contexts/RoleContextProvider";
import ThemeContextProvider from "../contexts/ThemeContextProvider";
import UserContextProvider from "../contexts/UserContextProvider";

export const allProvider = [
    ThemeContextProvider,
    AuthContextProvider,
    RoleContextProvider,
    UserContextProvider
];