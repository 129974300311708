import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import {ACCESS_TOKEN, PROFILE, REFRESH_TOKEN} from "../helpers/Constant";
import AuthService from '../services/AuthService';
import UserService from '../services/UserService';

export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem(ACCESS_TOKEN);
const profileData = localStorage.getItem(PROFILE);

const AuthContextProvider = ({children}) => {

    const [isLogin, setIsLogin] = useState(!!auth);
    const [profileLoading, setProfileLoading] = useState(false);
    const [profile, setProfile] = useState(profileData ? JSON.parse(profileData) : null);

    const [loading, setLoading] = useState(false);

    const login = async (values) => {

        try {

            setLoading(true);
            const res = await AuthService.login(values);
            console.log(res);
            localStorage.setItem(ACCESS_TOKEN, res.data.token.access);
            localStorage.setItem(REFRESH_TOKEN, res.data.token.refresh);
            setIsLogin(true);
            setLoading(false);

        } catch (error) {

            setLoading(false);
            setIsLogin(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }

    }


    const getUserProfile = async () => {

        try {

            setProfileLoading(true);

            const response = await UserService.getUserProfile();

            setProfile({...response.data});
        
            setProfileLoading(false);

        } catch (error) {

            setProfileLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            // Toast("error", Translator("common.error"), message);

        }
    }


    const logout = () => {
        setIsLogin(false);
        localStorage.clear();
    }

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                loading,
                login,
                logout,
                getUserProfile,
                profile,
                setProfile,
                profileLoading,
                setProfileLoading,
                permissions: profile ? profile.permissions : [],
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
