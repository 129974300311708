import React, { createContext, useState } from 'react';
import { Toast } from '../components/common/Toast';
import { getErrorMessage } from '../helpers/Utils';
import RoleService from '../services/RoleService';

export const RoleContext = createContext("RoleContext");

const RoleContextProvider = ({ children }) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [roleList, setRoleList] = useState([]);

    const getAllRole = async params => {
        try {

            setLoading(true);

            const res = await RoleService.getAllRole(params);
            setRoleList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <RoleContext.Provider
            value={{
                loading,
                totalElements,
                roleList,
                getAllRole
            }}
        >
            {children}
        </RoleContext.Provider>
    )
}

export default RoleContextProvider
