const API_VERSION = 'api/1.0.0';

const {REACT_APP_BASE_URL, REACT_APP_IMAGE_URL} = process.env;

const API_URL = `${REACT_APP_BASE_URL}/${API_VERSION}`;
const SECURED_URL = `${API_URL}/secured`;
const PUBLIC_URL = `${API_URL}`;

export const FILE_SERVER = REACT_APP_IMAGE_URL;
export const RETRIEVE_IMAGE_URL = `${PUBLIC_URL}/aws/get/files`;

//auth
export const LOGIN_URL = `${API_URL}/admins/login`;
export const USER_REFRESH_TOKEN = `${API_URL}/users/refresh-token`;

// profile
export const GET_USER_PROFILE = `${SECURED_URL}/users/profile`;

// role
export const CREATE_ROLE = `${SECURED_URL}/roles/create`;
export const GET_ALL_ROLE = `${SECURED_URL}/roles`;
export const GET_ALL_PERMISSION = `${SECURED_URL}/roles/permissions`;
export const GET_ROLE_BY_ID = `${SECURED_URL}/roles/id`;
export const UPDATE_ROLE = `${SECURED_URL}/roles/update`;
export const CHANGE_USER_PASSWORD = `${SECURED_URL}/users/change-password`;

// user
export const CREATE_USER = `${SECURED_URL}/users/create`;
export const GET_ALL_USER = `${SECURED_URL}/users`;
export const GET_USER_BY_ID = `${SECURED_URL}/users/id`;
export const UPDATE_USER = `${SECURED_URL}/users/update`;
export const UPDATE_USER_PROFILE = `${SECURED_URL}/users/profile/update`;


// restaurant
export const CREATE_RESTAURANT = `${SECURED_URL}/restaurants/create`;
export const GET_ALL_RESTAURANT = `${SECURED_URL}/restaurants`;
export const GET_RESTAURANT_BY_ID = `${SECURED_URL}/restaurants/by-id`;
export const GET_STATISTICS_RESTAURANT_BY_ID = `${SECURED_URL}/restaurants/latest/data/creation-report`;
export const UPDATE_RESTAURANT = `${SECURED_URL}/restaurants/update`;

// restaurant config
export const CREATE_RESTAURANT_CONFIG_URL = `${SECURED_URL}/restaurant-configs/save`;
export const GET_RESTAURANT_CONFIG_By_ID_URL = `${SECURED_URL}/restaurant-configs/id`;

// restaurant roles
export const GET_ROLES_BY_RESTAURANT = `${SECURED_URL}/restaurant-roles/by-restaurant`;
export const ASSIGN_RESTAURANT_ROLES = `${SECURED_URL}/restaurant-roles/assign`;

// image upload
export const UPLOAD_IMAGE = `${SECURED_URL}/aws/upload/any-file`;
export const UPLOAD_PROFILE_IMAGE = `${SECURED_URL}/users/s3/profile-pic/update`;

export const RETRIEVE_IMAGE = `https://d2h2d3rrei12ng.cloudfront.net`;

export const GOOGLE_MAP_PLACES = "https://maps.googleapis.com/maps/api/geocode/json";
