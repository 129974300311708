export const ACCESS_TOKEN = 'rms-super-admin-token';
export const REFRESH_TOKEN = 'rms-super-admin-refresh-token';
export const PROFILE = 'rms-portal-profile';

export const GOOGLE_MAP_API_KEY = "AIzaSyAEt4fldofQ7rUz8z4FgtzmSRjFPuZMbrY";

// language
export const ENGLISH_LANG = "en-us";

export const LANG_LIST = {
    "en-us": "English",
    "bn-bd": "Bengali",
    "ur-pk": "Urdu",
    "hi-in": "Hindi",
    "af-ps": "Pashto",
}

export const OWNER_ROLE_ALIAS="OWNER";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_YEAR_FORMAT = "YYYY";
export const DATE_MONTH_FORMAT = "MM";
export const TIME_FORMAT = "hh:mm:ss A";
export const TIME_FORMAT_TWO = "hh:mm A";

// color
export const PRIMARY_COLOR = "#AB323C";
export const SECONDARY_COLOR = "#787878";

export const CURRENCIES = {
    USD: "$",
    BDT: "৳",
    PNR: "Rs",
    INR: "₹",
    AFN: "؋"
}

export const ROLE_LEVEL = {
    ALL: "ALL",
    SYSTEM: "SYSTEM",
    RESTAURANT: "RESTAURANT",
    BRANCH: "BRANCH",
    CUSTOMER: "CUSTOMER",
}

export const ORDER_STATUS_COLOR = {
    ORDERED: "#169BD7",
    CONFIRMED_BY_RESTAURANT: "#99CC00",
    PREPARING: "#F50081",
    READY_FOR_DELIVERY: "#FFCC00",
    WAITING_FOR_PICKUP: "#0080FF",
    ON_THE_WAY_TO_DELIVERY: "#BF00FF",
    READY_TO_SERVE: "#FF8000",
    DELIVERED: "#009A00",
    CANCELLED: "#EB001B",
}

export const RESTAURANT_FOLDER = "RESTAURANT";