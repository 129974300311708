import {useLocation} from 'react-router-dom';
import Permission from "./Permission";
import {ACCESS_TOKEN, CURRENCIES, DATE_FORMAT, DATE_YEAR_FORMAT, TIME_FORMAT, TIME_FORMAT_TWO} from "./Constant";
import {RETRIEVE_IMAGE} from "../configs/APIUrl";
import dayjs from "dayjs";

export const DialingCodes = require("../json/DialingCodes.json");

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: query.get(key) || ""}
    }
    return data;
}

export const isValueExistInSearch = (data) => {

    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
}

export const getBase64Image = (data) => {
    const base64 = btoa(
        new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    return "data:;base64," + base64;
}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key]) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        if (index === 0) {
            result += `?${key}=${params[key]}`;
        } else {
            result += `&${key}=${params[key]}`;
        }

    });

    return result;
}

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
}

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: ""}
    }
    return data;
}

export const getAllNonObjectValues = (data) => {

    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
}

export const getAllNestedObjectValues = (data) => {
    let singleValue = [];

    for (const value of Object.values(data)) {
        if (value && typeof value === "object") {
            singleValue.push(value);
        }
    }
    return singleValue
}

export const getPercentageValue = (value, percent) => {
    return (value * percent) / 100;
}

export const hasPermission = (loggedInUserPermissions, permissions) => {

    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permission.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    for (const permission of permissions) {

        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }

    }

    return false;
}

export const apiUserAuth = () => {
    return {
        // Authorization: "Basic " + Buffer.from("shamim:aA12456").toString("base64")
        Authorization: "Basic dXNlcjp1c2Vy"
        // Authorization: "Basic " + btoa("shamim:aA12456")
    }
}

export const authorizationHeader = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return {"Authorization": `Bearer ${accessToken}`};
}

export const getErrorMessage = (error) => {
    if (error.response) {

        if (error.response.data) {
            return error.response.data.message || error.response.data.error;
        }

        return error.response.data;
    } else {
        return error.message;
    }
}

export const isRepresentativePresent = (value) => {

    if (!value) {
        return false;
    }

    const {rep_name, rep_mobile_code, rep_mobile, rep_email} = value;
    return !!(rep_name || rep_mobile_code || rep_mobile || rep_email);
}

export const checkUserType = (profile, type) => {
    return profile && profile.userType === type;
}

export const longTodayDate = () => {
    return dayjs() * 1;
}

export const longFromDate = () => {

    const dayjs1 = dayjs(Date.now() - 30 * 24 * 3600 * 1000);

    return dayjs1 * 1;
}

export const longFirstOfDate = (dateType, date = null) => {

    let dayjs1 = null;

    if (date) {
        dayjs1 = dayjs(date).startOf(dateType)
    } else {
        dayjs1 = dayjs().startOf(dateType);
    }

    return dayjs1 * 1;
}

export const longEndOfDate = (dateType, date) => {

    let dayjs1 = null;

    if (date) {
        dayjs1 = dayjs(date).endOf(dateType)
    } else {
        dayjs1 = dayjs().endOf(dateType);
    }

    return dayjs1 * 1;
}

export const longEndDateOfMonth = () => {

    const dayjs1 = dayjs().endOf("month");

    return dayjs1 * 1;
}

export const longToDate = date => {
    if (!date) return;
    return new Date(date);
}

export const dateToLong = date => {
    if (!date) return;
    return date * 1;
}

export const longToDateFormatter = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format("MMM D, YYYY");
}

export const longToDateFormatterTwo = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(DATE_FORMAT);
}

export const longToDateFormatterYear = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(DATE_YEAR_FORMAT);
}


export const longToTimeFormatter = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(TIME_FORMAT_TWO);
}

export const longToDateTimeFormatter = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
}

export const phoneNumberConcat = (mobileCode, phoneNumber) => {
    return `${mobileCode}-${phoneNumber}`;
}


export const getRetrieveImageUrl = (path) => {

    if (!path) return null;

    return `${RETRIEVE_IMAGE}/${path}`;
};

export const phoneNumberFormat = (number) => {
    if (!number) return "";
    return `${number.slice(0, 2)}-${number.slice(2, 5)}-${number.slice(5, 8)}-${number.slice(8, number.length)}`;
}

export const phoneNumberInputFormat = (number) => {
    if (!number) return "";
    const newNumber = number.slice(2);
    return `${newNumber.slice(0, 3)}-${newNumber.slice(3, 6)}-${newNumber.slice(6, newNumber.length)}`;
}

export const phoneNumberValidator = (_, number, emptyAllowed) => {
    if (!number && emptyAllowed) return Promise.resolve();
    if (/(\d{3})-(\d{3})-(\d{4})/.test(number) && number?.length === 12) {
        return Promise.resolve();
    } else {
        return Promise.reject("Invalid Phone Number")
    }
}

export const phoneNumberPurifier = number => {
    if (!number) return;
    return number.replace(/-/g, "");
}

export const getI18InputData = (data, locale = "en-us") => {

    if (!data || data.length < 1) return "";

    const find = data.find(v => v.language.toLowerCase() === locale);

    if (find) {

        return find;

    } else {

        const find = data.find(v => v.language.toLowerCase() === "en-us");

        if (find) {
            return find
        }

        return "";

    }

}


export const getCurrency = _currency => {

    const currency = _currency && _currency !== "null" ? JSON.parse(_currency).currency : "USD";

    return CURRENCIES[currency]
}

export const numberToDateTime = number => {

    const hours = number / 100;
    const minutes = number % 100;

    const date = new Date();

    date.setHours(hours, minutes);

    return date;
}

export const enumFormatter = value => {

    if (!value) return "";

    return value.replaceAll("_", " ");
}

export const getPercentage = (total, percentValue) => {

    if (total === 0) return 0.00;

    return ((percentValue / total) * 100).toFixed(2);
}

export const getRangeReportName = (title, from, to, format = DATE_FORMAT) => {
    return `${dayjs(from).format(format)}_TO_${dayjs(to).format(format)}_${title}`;
}

export const getDateReportName = (title, date, format = DATE_FORMAT) => {
    return `${dayjs(date).format(format)}_${title}`;
}

export const getDialingNumberByCountryCode = countryCode => {
    return DialingCodes.find(dialingCode => dialingCode.code.toUpperCase() === countryCode.toUpperCase())
}

export const getPhoneLengthByCountryCode = countryCode => {

    const find = DialingCodes.find(dialingCode => dialingCode.code.toUpperCase() === countryCode.toUpperCase());

    if (find) {
        return find.phoneLength
    }

    return 0;
}
