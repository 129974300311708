import React, {createContext, useState} from 'react';

export const ThemeContext = createContext("ThemeContext");
const ThemeContextProvider = ({children}) => {

    const [screenWidth, setScreenWidth] = useState(0);

    return (
        <ThemeContext.Provider
            value={{
                screenWidth,
                setScreenWidth,
            }}
        >
            {children}
        </ThemeContext.Provider>
    );
}

export default ThemeContextProvider;
