import { ConfigProvider } from "antd";
import { lazy, Suspense, useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoadingSuspense from "./components/common/LoadingSuspense";
import PrivateRoute from "./components/common/PrivateRoute";
import { AuthContext } from "./contexts/AuthContextProvider";
import { ThemeContext } from "./contexts/ThemeContextProvider";
import Interceptors from "./rest_handlers/Interceptors";
import { LOGIN_PATH, PAGE_403_PATH, PAGE_404_PATH, PAGE_500_PATH } from "./routes/Slug";
import { lightComponentsToken, lightToken } from "./them_token/light";

const DefaultLayout = lazy(() => import("./components/layout/DefaultLayout"));
const Login = lazy(() => import("./pages/login/Login"));
const Page403 = lazy(() => import("./pages/error_pages/Page403"));
const Page404 = lazy(() => import("./pages/error_pages/Page404"));
const Page500 = lazy(() => import("./pages/error_pages/Page500"));

const App = () => {

    const {isLogin} = useContext(AuthContext);
    const {setScreenWidth} = useContext(ThemeContext);

    useEffect(() => {
        setScreenWidth(window.innerWidth);

        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateDimensions = () => {
        setScreenWidth(window.innerWidth);
    }

    return (
        <ConfigProvider
            theme={{
                token: {...lightToken},
                components: lightComponentsToken
            }}
        >
            <div className="app-wrapper">
                <Suspense fallback={<LoadingSuspense/>}>
                    <BrowserRouter>
                        <Routes>
                            <Route path={LOGIN_PATH} element={<Login/>}/>
                            <Route element={<PrivateRoute isLogin={isLogin} />}>
                                <Route path={PAGE_404_PATH} element={<Page404/>}/>
                                <Route path={PAGE_403_PATH} element={<Page403/>}/>
                                <Route path={PAGE_500_PATH} element={<Page500/>}/>
                                <Route path="*" element={<DefaultLayout/>}/>
                            </Route>
                        </Routes>
                        <Interceptors/>
                    </BrowserRouter>
                </Suspense>
            </div>
        </ConfigProvider>
    );
}

export default App;
