const Permission = {
    ALL: "ALL",
    READ_USER_PROFILE: "READ_USER_PROFILE",
    READ_ROLE: "READ_ROLE",
    CREATE_ROLE: "CREATE_ROLE",
    MODIFY_ROLE: "MODIFY_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    READ_PERMISSION: "READ_PERMISSION",
    READ_RESTAURANT: "READ_RESTAURANT",
    CREATE_RESTAURANT: "CREATE_RESTAURANT",
    MODIFY_RESTAURANT: "MODIFY_RESTAURANT",
    READ_USER: "READ_USER",
    CREATE_USER: "CREATE_USER",
    MODIFY_USER: "MODIFY_USER",
    READ_RESTAURANT_CONFIG: "READ_RESTAURANT_CONFIG",
    CREATE_RESTAURANT_CONFIG: "CREATE_RESTAURANT_CONFIG",
    MODIFY_RESTAURANT_CONFIG: "MODIFY_RESTAURANT_CONFIG",
}

export default Permission;