const PRIMARY_COLOR = "#AB323C";
const PRIMARY_COLOR_FADE = "#AB323C21";
const PRIMARY_COLOR_FADE_2 = "#EBDEDD";
const INPUT_HOVER_COLOR = "#000000"
const WHITE = "#FFFFFF"
const TEXT_BASE_COLOR = "#3A3A3A"

export const lightToken = {
    colorPrimary: PRIMARY_COLOR,
    fontFamily: "'Barlow Condensed', 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif",
    colorInfo: PRIMARY_COLOR,
    colorInfoBg: PRIMARY_COLOR_FADE,
    colorInfoBorder: PRIMARY_COLOR,
    descriptionBg: WHITE, // Description background
    customBorderColor: PRIMARY_COLOR_FADE_2, // Use this variable in jsx
    customBGC: WHITE,
    colorTextBase: TEXT_BASE_COLOR,
}

export const lightComponentsToken = {
    Input: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    InputNumber: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    Select: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    DatePicker: {
        colorPrimaryHover: INPUT_HOVER_COLOR
    },
    Table: {
        colorFillAlter: WHITE,
        // colorFillAlter: PRIMARY_COLOR_FADE_2,
    },
}